import { createSubmission } from '@wix/ambassador-forms-v4-submission/http';
import { FormSubmission } from '@wix/ambassador-forms-v4-submission/types';
import {
  startPlanCustomization,
  updatePlanCustomization,
  checkout,
} from '@wix/ambassador-pricing-plans-plan-customization-v1-plan-customization/http';
import {
  PlanCustomization,
  FormSubmissionData,
} from '@wix/ambassador-pricing-plans-plan-customization-v1-plan-customization/types';
import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import type { FormValues } from '@wix/form-viewer';
import { initFormController } from '@wix/form-viewer/controller';
import { CheckoutPublicData } from '@wix/pricing-plans-router-utils';
import type {
  CreateControllerFn,
  ControllerParams,
  IHttpClient,
  IUser,
  ControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import { WIX_FORMS_NAMESPACE } from '../../constants/wix-forms';
import { resolveLocale } from '../../utils';
import { RootProductPageProps } from './Widget';

const ECOM_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';

const createController: CreateControllerFn = async ({ flowAPI }: ControllerParams) => {
  return {
    async pageReady() {
      const setProps: (props: Partial<RootProductPageProps & { fitToContentHeight?: boolean }>) => void =
        flowAPI.controllerConfig.setProps;
      const wixCodeApi = flowAPI.controllerConfig.wixCodeApi;
      const customizationApi = new PlanCustomizationApi(flowAPI.httpClient);
      // TODO: Implement router/routerData & remove TEMP_checkoutData
      const routerData =
        wixCodeApi.window.getRouterPublicData<CheckoutPublicData>() ?? (await TEMP_checkoutData(flowAPI));

      setInitialProps();
      const currentUser = wixCodeApi.user.currentUser;
      if (currentUser.loggedIn) {
        initForUser(currentUser, routerData.plan);
      } else {
        setProps({ isLoading: false, plan: routerData.plan, user: null });
        wixCodeApi.user.onLogin(async () => {
          // Refetching plan, since initial request doesn't return formId for visitor identity
          // TODO: Figure it out
          const plan = await TEMP_getPlan(routerData.plan.id!, flowAPI.httpClient);
          initForUser(wixCodeApi.user.currentUser, plan);
        });
      }

      // Internal functions below
      // =========================
      async function initForUser(user: IUser, plan: PublicPlan) {
        await maybeInitFormController(plan);
        const userData = {
          email: await user.getEmail(),
          role: user.role,
        };
        setProps({
          plan,
          user: userData,
        });
        await startCustomization(plan, user.id);
      }

      async function maybeInitFormController(plan: PublicPlan) {
        if (plan.formId) {
          await initFormController(flowAPI, { formId: plan.formId!, namespace: WIX_FORMS_NAMESPACE });
        }
      }

      async function startCustomization(plan: PublicPlan, userId: string): Promise<void> {
        const customizedPlan = await customizationApi.startPlanCustomization({ planId: plan.id!, memberId: userId });
        setProps({ customizedPlan });
      }

      async function setPlanStartDate(planCustomization: PlanCustomization, date: Date) {
        const updatedCustomization = await customizationApi.setStartDate({ planCustomization, date });
        setProps({ customizedPlan: updatedCustomization });
      }

      function setInitialProps() {
        setProps({
          isLoading: true,
          fitToContentHeight: true,
          locale: resolveLocale(wixCodeApi),
          promptLogin: () => wixCodeApi.user.promptLogin({ mode: 'login', modal: true }),
          promptSignup: () => wixCodeApi.user.promptLogin({ mode: 'signup', modal: true }),
          setPlanStartDate,
          isCheckoutInProgress: false,
          proceedToPayment: async (customization: PlanCustomization, formValues?: FormValues) => {
            setProps({ isCheckoutInProgress: true });
            try {
              let finalCustomization = customization;
              if (formValues) {
                // TODO: Check if sumbission could be done in the widget
                finalCustomization = await customizationApi.submitAndSetFormValues(
                  customization,
                  customization.plan!.formId!,
                  formValues,
                );
              }
              const checkoutData = await customizationApi.checkout(finalCustomization);

              if (checkoutData?.id) {
                const ecomApi = await wixCodeApi.site.getPublicAPI(ECOM_APP_DEF_ID);
                await ecomApi.navigate.toCheckout({
                  checkoutId: checkoutData.id,
                  disableContinueShopping: true,
                });
              } else {
                setProps({ isCheckoutInProgress: false });
              }
            } catch (e) {
              console.error(e);
              setProps({ isCheckoutInProgress: false });
            }
          },
        });
      }
    },
    async updateConfig() {},
  };
};

export default createController;

class PlanCustomizationApi {
  constructor(private readonly httpClient: IHttpClient) {}

  // TODO: Move to separate form controller
  async submitForm(formId: string, values: FormValues): Promise<FormSubmission> {
    const result = await this.httpClient.request(
      createSubmission({
        submission: {
          formId,
          submissions: values,
        },
      }),
    );
    return result.data.submission!;
  }

  async startPlanCustomization(params: { planId: string; memberId: string }): Promise<PlanCustomization> {
    const { planId, memberId } = params;
    const result = await this.httpClient.request(startPlanCustomization({ planId, member: { memberId } }));
    return result.data.planCustomization!;
  }

  async startGuestPlanCustomization(params: {
    planId: string;
    email: string;
    captchaToken: string;
  }): Promise<PlanCustomization> {
    const { planId, email, captchaToken } = params;
    const result = await this.httpClient.request(
      startPlanCustomization({ planId, visitor: { email, captcha: { token: captchaToken } } }),
    );
    return result.data.planCustomization!;
  }

  async setStartDate(params: { planCustomization: PlanCustomization; date: Date }): Promise<PlanCustomization> {
    const { planCustomization, date } = params;
    const result = await this.httpClient.request(
      updatePlanCustomization({
        fieldMask: ['customization.startDate'],
        planCustomization: {
          id: planCustomization.id,
          revision: planCustomization.revision,
          customization: { startDate: date },
        },
      }),
    );
    return result.data.planCustomization!;
  }

  async submitAndSetFormValues(
    planCustomization: PlanCustomization,
    formId: string,
    formValues: FormValues,
  ): Promise<PlanCustomization> {
    const formSubmission = await this.submitForm(formId, formValues);
    if (formSubmission) {
      const result = await this.setFormValues(planCustomization, {
        submissionData: formSubmission.submissions,
        submissionId: formSubmission.id,
      });
      return result ?? planCustomization;
    }
    return planCustomization;
  }

  async setFormValues(
    planCustomization: PlanCustomization,
    formSubmissionData: FormSubmissionData,
  ): Promise<PlanCustomization> {
    const result = await this.httpClient.request(
      updatePlanCustomization({
        fieldMask: ['customization.formSubmissionData'],
        planCustomization: {
          id: planCustomization.id,
          revision: planCustomization.revision,
          customization: {
            // TODO: Check if startDate is persisted
            formSubmissionData,
          },
        },
      }),
    );
    return result.data.planCustomization!;
  }

  async checkout(planCustomization: PlanCustomization): Promise<{ id: string | null } | undefined> {
    const result = await this.httpClient.request(checkout({ planCustomizationId: planCustomization.id! }));
    // eslint-disable-next-line no-console
    console.log('result', JSON.stringify(result.data));
    return {
      id: result.data.checkoutId!,
    };
  }
}

async function TEMP_getPlan(planId: string, httpClient: IHttpClient): Promise<PublicPlan> {
  const { data } = await httpClient.request(listPublicPlans({ planIds: [planId] }));
  return data.plans![0]!;
}

async function TEMP_checkoutData(flowAPI: ControllerFlowAPI): Promise<CheckoutPublicData> {
  const planId = flowAPI.controllerConfig.wixCodeApi.location.query.planId;
  return {
    plan: await TEMP_getPlan(planId, flowAPI.httpClient),
    // @ts-expect-error
    checkoutData: {},
  };
}
